import React from 'react'
import classes from './Form.module.css'
import Paper from '@material-ui/core/Paper'
import Title from './Title/Title'
import Input from './Input/Input'
import Button from './Button/Button'
import Spinner from '../../UI/Spinner/Spinner'
import Alert from '@material-ui/lab/Alert'

export default props => {
  let error = null
  let alert = null

  if (props.error) {
    error = (
      <Alert
        className={classes.Alert}
        severity="error"
        onClose={props.closeError}
      >
        {props.errorMessage}
      </Alert>
    )
  }

  if (props.successMessage) {
    alert = (
      <Alert
        className={classes.Alert}
        severity="success"
        onClose={props.closeAlert}
      >
        {props.successMessage}
      </Alert>
    )
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      if (!props.disabled) props.onSubmit()
    }
  }

  return (
    <Paper elevation={3} className={classes.Form} onKeyPress={handleKeyPress}>
      <div>
        {error}
        {alert}
        <Title text={props.title} />
        {props.inputs.map(i => (
          <Input
            key={i.id}
            id={i.id}
            label={i.config.label}
            type={i.config.elementConfig.type}
            placeholder={i.config.elementConfig.placeholder}
            chageHandler={event => props.chageHandler(event, i.id)}
            touched={i.config.touched}
            valid={i.config.valid}
            helperText={i.config.helperText}
            required={i.config.validation.required}
            multiline={i.config.elementConfig.multiline}
            value={i.config.value}
            disabled={props.spinner ? true : false}
          />
        ))}
        {props.checkbox ? props.checkbox : null}
        <div className={[classes.Option, classes.Link].join(' ')}>
          {props.links
            ? props.links.map((l, i) => (
                <span key={i}>
                  {l}
                  <br />
                </span>
              ))
            : null}
        </div>
        <div className={classes.Option}>
          {props.options
            ? props.options.map((o, i) => (
                <span key={i}>
                  {o}
                  <br />
                </span>
              ))
            : null}
        </div>
        {props.spinner ? <Spinner /> : null}
        <Button
          text={props.title}
          click={props.onSubmit}
          disabled={props.disabled}
        />
      </div>
    </Paper>
  )
}
