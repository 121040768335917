import React, { Component } from 'react'
import { updateObject, checkValidity } from '../../../shared/utility'
import axios from '../../../utils/axiosBackAPI'
import SEO from '../../seo'
import Form from '../../UI/Form/Form'

class Contact extends Component {
  state = {
    form: {
      name: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'your name'
        },
        value: '',
        label: 'Your Name',
        validation: {},
        valid: false,
        touched: false
      },
      email: {
        elementType: 'input',
        elementConfig: {
          type: 'email',
          placeholder: 'example@gmail.com'
        },
        value: '',
        label: 'Email',
        validation: {
          required: true,
          isEmail: true
        },
        helperText: 'Not a valid email',
        valid: false,
        touched: false
      },
      message: {
        elementType: 'input',
        elementConfig: {
          type: 'text',
          placeholder: 'Your message...',
          multiline: true
        },
        value: '',
        label: 'Your Message',
        validation: {
          required: true,
          minLength: 20
        },
        helperText: 'At least 20 characters',
        valid: false,
        touched: false
      }
    },
    formIsValid: false,
    loading: false,
    submitted: false,
    error: false,
    errorMessage: 'Unfortunately something went wrong. Please try again.'
  }

  inputChangedHandler = (event, inputIdentifier) => {
    const updateFormElement = updateObject(this.state.form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        this.state.form[inputIdentifier].validation
      ),
      touched: true
    })

    const updatedForm = updateObject(this.state.form, {
      [inputIdentifier]: updateFormElement
    })

    let formIsValid = true

    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid
    }

    this.setState({
      form: updatedForm,
      formIsValid: formIsValid
    })
  }

  handleSubmit = () => {
    this.setState({ loading: true })

    const data = {
      name: this.state.form.name.value,
      email: this.state.form.email.value,
      message: this.state.form.message.value,
      to: 'erudicat'
    }

    axios
      .post('/api/contact', data)
      .then(response => {
        const confirmation = response.data.confirmation

        if (confirmation === 'success') this.setState({ submitted: true })
        else this.setState({ error: true })

        this.setState({ loading: false })
      })
      .catch(err => {
        this.setState({ error: true, loading: false })
      })
  }

  closeAlertHandler = () => {
    this.setState({
      submitted: false
    })
  }

  closeErrorHandler = () => {
    this.setState({
      error: false
    })
  }

  render() {
    const options = [
      <span>
        Or just send us an email to{' '}
        <a href="mailto:info.erudicat@gmail.com">info.erudicat@gmail.com</a>
      </span>
    ]

    const formElements = []

    for (let key in this.state.form) {
      formElements.push({
        id: key,
        config: this.state.form[key]
      })
    }

    return (
      <>
        <SEO
          title="PMP Exam Preparation | Contact Us | PMP Exam Simulator"
          keywords={[`PMP`, `PMP Exam`, `Project Management`]}
          description="Practice with PMP Exam Simulator. Contact Us About Anything. PMP Training With Sample Questions."
        ></SEO>
        <Form
          error={this.state.error}
          closeError={this.closeErrorHandler}
          errorMessage={this.state.errorMessage}
          successMessage={
            this.state.submitted ? `Thanks! We'll be in touch shortly.` : null
          }
          closeAlert={this.closeAlertHandler}
          title="Contact Us"
          inputs={formElements}
          onSubmit={this.handleSubmit}
          chageHandler={this.inputChangedHandler}
          spinner={this.state.loading}
          disabled={!this.state.formIsValid}
          options={options}
        />
      </>
    )
  }
}

export default Contact
