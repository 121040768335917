import React from 'react'
import classes from './Button.module.css'
import Button from '@material-ui/core/Button'

export default props => (
  <div className={classes.ButtonWrapper}>
    <Button
      variant="contained"
      style={{
        backgroundColor: '#604f93',
        color: 'white',
        fontWeight: '550',
        lineHeight: 'unset'
      }}
      className={classes.Button}
      onClick={props.click}
      disabled={props.disabled}
    >
      {props.text}
    </Button>
  </div>
)
