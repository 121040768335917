import React from 'react'
import Layout from '../hoc/Layout/Layout'
import Contact from '../components/Pages/Contact/Contact'

export default () => {
  return (
    <Layout>
      <Contact />
    </Layout>
  )
}
